<template>
  <div>
    <div class="grid-stack">
      <div
        class="grid-stack-item"
        data-gs-x="0"
        data-gs-y="0"
        data-gs-width="8"
        data-gs-height="2"
      >
        <Welcome></Welcome>
      </div>

      <div
        class="grid-stack-item"
        data-gs-x="0"
        data-gs-y="2"
        data-gs-width="8"
        data-gs-height="2"
      >
        <Sections></Sections>
      </div>

      <div
        id="ClientsContactPartner"
        class="grid-stack-item"
        data-gs-x="10"
        data-gs-y="0"
        data-gs-width="2"
        data-gs-height="4"
      >
        <ClientsContactPersons :key="partnerApiKey" />
      </div>
    </div>
  </div>
</template>

<script>
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Welcome from "@/components/Partners/Welcome/Apps/Welcome";
import Sections from "@/components/Partners/Welcome/Apps/Sections";
import ClientsContactPersons from "@/components/General/Dashboard/Apps/Clients/ContactPerson";
import { mapGetters } from "vuex";

export default {
  name: "PartnersWelcomeIndex",
  components: {
    Welcome,
    Sections,
    ClientsContactPersons
  },
  data() {
    return {
      gridStack: null,
      partnerApiKey: null
    };
  },
  computed: {
    ...mapGetters(["selectedPartner"])
  },
  watch: {
    selectedPartner: function () {
      this.partnerApiKey = this.$store.getters.apiTokenPartner;
    }
  },
  created() {
    this.partnerApiKey = this.$store.getters.apiTokenPartner;
  },
  mounted() {
    this.$forceUpdate();
    this.loadGridStack();

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.dashboard"),
        route: "/partner/welcome"
      }
    ]);
  },
  beforeDestroy() {
    this.removeGridStack();
  },
  methods: {
    loadGridStack() {
      const options = {
        cellHeight: 80,
        margin: 10
      };

      this.gridStack = GridStack.init(options);
    },
    removeGridStack() {
      if (!this.gridStack) return;
      this.gridStack.destroy(true);
    }
  }
};
</script>
