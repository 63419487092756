<template>
  <div class="card card-custom grid-stack-item-content">
    <div class="card-body">
      <h1>
        {{ $t("dashboard.welcomeToPartner")
        }}<span v-if="partner" class="text-capitalize"
          >,
          {{ partner.partnerName ? partner.partnerName : partner.name }}</span
        >!
      </h1>
      <p>{{ $t("dashboard.partnerShortDescription") }}</p>
    </div>
  </div>
</template>

<script>
import Auth from "@/components/General/Auth/auth";
import { mapGetters } from "vuex";

export default {
  name: "PartnersWelcomeAppsWelcome",
  data: function () {
    return {
      partner: null
    };
  },
  computed: {
    ...mapGetters(["selectedPartner"])
  },
  watch: {
    selectedPartner: function () {
      this.getPartnerData();
      this.partner = this.selectedPartner;
    }
  },
  mounted() {
    this.partner = this.selectedPartner;
    this.getPartnerData();
  },
  methods: {
    getPartnerData() {
      let me = this;

      const headers = {
        headers: {
          "x-api-key": this.$store.getters.apiTokenPartner
        }
      };

      Auth.getMeTenant(headers).then(response => {
        me.partner = response.data.data.tenantable;
      });
    }
  }
};
</script>
